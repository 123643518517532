import 'whatwg-fetch'

interface RequestOptions {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  headers?: {}
  body?: string
  credentials?: 'same-origin'
}

interface RequestParams {
  [key: string]: string | number
}

export const get = async <T>(url: string, params: RequestParams = {}): Promise<T> => {
  const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return request<T>(url + "?" + qs, { method: 'GET' });
}

export const post = async <T>(url: string, data: {}): Promise<T> => {
  return request<T>(url, { method: 'POST', body: JSON.stringify(data) });
}

export const patch = async <T>(url: string, data: {}): Promise<T> => {
  return request<T>(url, { method: 'PATCH', body: JSON.stringify(data) });
}

export const put = async <T>(url: string, data: {}): Promise<T> => {
  return request<T>(url, { method: 'PUT', body: JSON.stringify(data) });
}

export const del = async <T>(url: string, params: RequestParams = {}): Promise<T> => {
  const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return request<T>(url + "?" + qs, { method: 'DELETE' });
}

export const poll = async <T>(delay: number, url: string, params: RequestParams = {}, onData: (responseData: T) => any) => {
  const run = () => {
    get<T>(url, params).then((responseData) => {
      onData(responseData);
      setTimeout(run, delay);
    })
  }
  run();
}

const request = async <T>(url: string, options: RequestOptions): Promise<T> => {
  const csrfToken = document.querySelector("[name='csrf-token']")?.getAttribute("content")

  options.headers = {
    "X-CSRF-Token": csrfToken,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };
  options.credentials = 'same-origin';

  return new Promise((resolve, reject) => {
    fetch(url, options)
    .then(response => {
      response.json().then(json => {
        if (response.ok) {
          resolve(json);
        } else {
          reject(json);
        }
      })
    })
    .catch(err => {
      reject(err);
    });
  });
}

