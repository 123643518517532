import { Controller } from 'stimulus'
import * as d3 from 'd3'
import {geoCylindricalStereographic as geoProjection} from 'd3-geo-projection'
const debounce = require('lodash/debounce')

const RESIZE_DELAY = 1000; // milliseconds

export default class ProjectsMapController extends Controller {
  static classes = ["region", "forestryProject", "carbonProject"]

  initialize() {
    this.resize = debounce(this.resize, RESIZE_DELAY).bind(this)

    const countriesDataUrl = this.data.get("countriesDataUrl");
    const countriesDataRequest = d3.json(countriesDataUrl);

    const projectsDataUrl = this.data.get("projectsDataUrl");
    const projectsDataRequest = d3.json(projectsDataUrl);

    Promise.all([countriesDataRequest, projectsDataRequest]).then(res => {
      [this.countriesData, this.projectsData] = res;
      this.#render(this.element, this.countriesData, this.projectsData);
    });
  }

  resize() {
    this.#render(this.element, this.countriesData, this.projectsData);
  }

  #render(element, countriesData, projectsData) {
    const container = d3.select(element);
    container.select('svg').remove();

    const forestryProjectsData = projectsData.filter(d => d.type == "forestry");
    const carbonProjectsData = projectsData.filter(d => d.type == "carbon");

    const containerWidth = container.node().clientWidth;
    const containerHeight = container.node().clientHeight;

    const svg = container
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`);

    // Projection and path.
    const projection = geoProjection().fitSize([containerWidth, containerHeight], countriesData);
    const geoPath = d3.geoPath().projection(projection);

    const regionClasses = this.regionClasses.join(" ");
    const forestryProjectClasses = this.forestryProjectClasses.join(" ");
    const carbonProjectClasses = this.carbonProjectClasses.join(" ");

    let map = svg.append("g")
      .attr("class", "map");

    map.selectAll("path")
      .data(countriesData.features)
      .enter()
      .append("path")
      .attr("class", `region ${regionClasses}`)
      .attr("d", geoPath);

    const markerHeightPercentage = projectsData.length == 1 ? 0.08 : 0.05;

    const triangleD = "M8.41098 122L44.0545 18.457L79.5968 122H8.41098Z";
    const triangleNaturalWidth = 88;
    const triangleNaturalHeight = 128;
    const triangleHeight = containerHeight * markerHeightPercentage;
    const triangleScale = triangleHeight / triangleNaturalHeight;
    const triangleWidth = triangleNaturalWidth * triangleScale;

    map.selectAll(".forestry-project-marker")
      .data(forestryProjectsData)
      .enter()
      .append("g")
      .attr("class", "forestry-project-marker")
      .append("path")
      .attr("class", forestryProjectClasses)
      .attr("d", triangleD)
      .attr("transform", function(d) {
        const xy = projection([d.lng, d.lat]);
        const x = xy[0] - (triangleWidth / 2);
        const y = xy[1] - triangleHeight + Math.sqrt(triangleHeight);
        return `translate(${x}, ${y}) scale(${triangleScale}, ${triangleScale})`;
      });

    const hexD = "M6.57438 35.4641L56 6.9282L105.426 35.4641V92.5359L56 121.072L6.57438 92.5359V35.4641Z";
    const hexNaturalWidth = 112;
    const hexNaturalHeight = 128;
    const hexHeight = containerHeight * markerHeightPercentage;
    const hexScale = hexHeight / hexNaturalHeight;
    const hexWidth = hexNaturalWidth * hexScale;

    map.selectAll(".carbon-project-marker")
      .data(carbonProjectsData)
      .enter()
      .append("g")
      .attr("class", "carbon-project-marker")
      .append("path")
      .attr("class", carbonProjectClasses)
      .attr("d", hexD)
      .attr("transform", function(d) {
        const xy = projection([d.lng, d.lat]);
        const x = xy[0] - (hexWidth / 2);
        const y = xy[1] - (hexHeight / 2);
        return `translate(${x}, ${y}) scale(${hexScale}, ${hexScale})`;
      });
  }
}
