import { Controller } from "stimulus";

export default class SubmittableController extends Controller {
  static targets = ["form"];

  submit() {
    let target = this.formTarget;

    target.querySelectorAll('[type="submit"]').forEach(function(el) {
      el.disabled = true;
      el.classList.add('disabled');
      el.blur();
    });

    target.querySelectorAll('[data-loading-html]').forEach(function(el) {
      el.innerHTML = el.dataset.loadingHtml;
    });

    target.querySelectorAll('[data-loading-class]').forEach(function(el) {
      el.classList.add(el.dataset.loadingClass);
    });

    target.submit();
  }
}
