import c0 from './city_search_controller';
import c1 from './dropdown_controller';
import c2 from './mobile_nav_controller';
import c3 from './projects_map_controller';
import c4 from './tabs_controller';
export const definitions = [
	{identifier: 'city-search', controllerConstructor: c0},
	{identifier: 'dropdown', controllerConstructor: c1},
	{identifier: 'mobile-nav', controllerConstructor: c2},
	{identifier: 'projects-map', controllerConstructor: c3},
	{identifier: 'tabs', controllerConstructor: c4},
];
