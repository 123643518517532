import { Notifier } from '@airbrake/browser'

let airbrake = null
let environment = 'production'

if (environment != "development") {
  airbrake = new Notifier({
    projectId: 491153,
    projectKey: 'b2922674fec23a2f1af89d9ccd0b87be',
    environment: 'production'
  })
}

export default airbrake